import React, { Fragment, memo } from 'react';
import { useSnapshot } from 'valtio';
import { SignalrOptionRefQuote } from '~/modules/SDK/socket/SignalrOptionRefQuote';
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState';
import { AtThePriceAvgTimeValueBox } from '~/modules/options/sharedComponents/AtThePriceAvgTimeValueBox';
import { BackTestToggle } from '~/modules/options/sharedComponents/BackTestToggle';
import { SoftDayToSelect } from '~/modules/options/sharedComponents/ComposedDayRangeSelect/DayToSelect';
import { SyntheticFuturesQuote } from '~/modules/options/sharedComponents//SyntheticFuturesQuote';
import { useSyntheticIndexStore } from '~/modules/options/shareContainers/useSyntheticIndexStore';
import { useMount, useUnmount } from 'react-use';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
export const BackTest = memo(function _BackTest() {
    const { state } = useOptionsSourceState.useContainer();
    return (<>
      <BackTestToggle />
      {state.isBackTest && <SoftDayToSelect />}
    </>);
});
const signalrSymbols = ['TX-1', 'TSEA'];
export const RelativeQuotes = memo(function _Quotes() {
    const s = useSnapshot(useSyntheticIndexStore);
    useMount(() => {
        signalrStore2.addQuote(signalrSymbols);
    });
    useUnmount(() => {
        signalrStore2.removeQuote(signalrSymbols);
    });
    return (<Fragment>
      <SignalrOptionRefQuote symbol={'TX-1'}/>
      <SignalrOptionRefQuote symbol={'TSEA'}/>
      <SyntheticFuturesQuote syntheticIndexPrice={s.syntheticIndexPrice}/>
      <AtThePriceAvgTimeValueBox atThePriceAvgTimeValue={s.atThePriceAvgTimeValue}/>
    </Fragment>);
});
