import { FormControl, InputLabel, MenuItem, outlinedInputClasses, Select } from '@mui/material';
import { css } from '@emotion/react';
import React, { memo, useEffect } from 'react';
import { Loading } from '~/components/Loading';
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState';
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState';
import { useOpcontractsResource } from '~/modules/options/api/useOpcontractsResource';
/**
 * 選擇合約 利用 useOptionDaysState 取得當下該範圍的有效合約
 *
 * opbs, option-compare, option-price共用
 */
const ContractsSelect = memo(function ContractsSelect(props) {
    const { state, acts } = useOptionContractCodeState.useContainer();
    const { state: dayState } = useOptionDaysState.useContainer();
    const daySelect = useOptionDaysState.useContainer();
    const opcontracts = useOpcontractsResource({
        from: daySelect.state.fromDay,
        to: daySelect.state.toDay,
    }).res.data;
    const month = state.month;
    const setMonth = acts.setMonth;
    const slidingWindowMode = dayState.slidingWindowMode;
    const outOfSelectable = month && !opcontracts?.contractMonth?.includes(month);
    const loading = !opcontracts?.default || outOfSelectable;
    useEffect(() => {
        if (!slidingWindowMode && (outOfSelectable || !month)) {
            acts.setMonth(opcontracts?.default);
        }
    });
    useEffect(() => {
        if (!month) {
            return;
        }
        props.onChanged?.(month);
        return () => {
            if (!month) {
                return;
            }
            props.onDemount?.(month);
        };
    }, [month, props, props.onChanged]);
    const defaultContract = month
        ? month
        : opcontracts && opcontracts?.contractMonth
            ? opcontracts.default ?? ''
            : '請選擇合約';
    return (<FormControl variant='outlined' css={css `
        width: 120px;
        .${outlinedInputClasses.input} {
          padding: 12px 8px;
        }
      `}>
      <InputLabel>合約</InputLabel>
      {loading ? (<Loading />) : (<Select label='合約' disabled={props.disabled} defaultValue={defaultContract} labelId='contracts-outlined-select-label' onChange={e => setMonth(e.target.value)}>
          {opcontracts?.contractMonth?.map((contractMonthValue, index) => {
                return (<MenuItem value={contractMonthValue} key={index}>
                {contractMonthValue}
              </MenuItem>);
            })}
        </Select>)}
    </FormControl>);
});
export default ContractsSelect;
